<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import SaveButton from "@/components/buttons/save-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Admin ${modeName}`,
            modeName,
            items: [
                {
                    text: "Admin"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                status: 1,
                name: '',
                email: '',
                roles: null,
                password: '',
                password_confirmation: '',
            },
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            roleOptions: [
                { name: 'SuperAdmin', value: 1 },
                { name: 'Management', value: 2 },
            ],
            errors: {},
        };
    },
    computed: {

    },
    async mounted() {
        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/admin/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['admin'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }

                if (data.role !== null) {
                    this.data.roles = data.role.id;
                }
            }
        }        
    },
    methods: {
        async save() {
            this.errors = {};
            let result;
            if (this.modeName === 'Add') {
                const requestData = this.data;
                result = await axios.post(`${process.env.VUE_APP_APIURL}/admin`, requestData, {
                    headers: authHeader(),
                });
            } else {
                const filteredPairs = Object.entries(this.data).filter(([key]) => !['password', 'password_confirmation'].includes(key));
                const requestData = Object.fromEntries(filteredPairs);
                result = await axios.put(`${process.env.VUE_APP_APIURL}/admin/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                } else if (result.data['code'] === 'invalid_status') {
                    this.$refs.messageModal.showModal('Unable to change your own status to inactive!');
                }
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-12 mr-2 mt-2  mb-2">
                                <SelectGroup id="status" displayName="Status" v-model="data.status" :error="errors.status" :options="statusOptions" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="name" displayName="Name" v-model="data.name" :error="errors.name" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="email" id="email" displayName="Email" v-model="data.email" :error="errors.email" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="role" displayName="Role" v-model="data.roles" :error="errors.roles" :options="roleOptions" />
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 col-md-4 mb-2" v-if="modeName === 'Add'">
                                <InputGroup type="password" id="password" displayName="Password" v-model="data.password" :error="errors.password" />
                                <InputGroup type="password" id="password_confirmation" displayName="Password Confirmation" v-model="data.password_confirmation" :error="errors.password" />
                            </div>
                            <!-- <div class="col-12 mb-4 text-right" v-if="modeName !== 'Add'">
                                <div class="form-group">
                                    <button class="btn btn-primary btn-md" type="button" @click="removeGoogle2FA">Reset Google 2FA</button>
                                </div>
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <SaveButton @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</Layout></template>